import React from 'react';
import { Parallax as ReactParallax, Background } from 'react-parallax';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Carousel } from 'react-bootstrap';
import { useNavigate } from '@reach/router';
import Header from '../Header';
import Typography from '../Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grid: {
    height: '90vh',
  },
  info: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: '#ffffff',
  },
  img: {
    backgroundImage: `linear-gradient(to left, #1FA2FF, #12D8FA, #A6FFCB), url(
      https://raw.githubusercontent.com/BlackrockDigital/startbootstrap-new-age/master/img/bg-pattern.png
    )`,
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
  },
  pattern: {
    backgroundRepeat: 'repeat-y',
    backgroundImage:
      'url(https://raw.githubusercontent.com/BlackrockDigital/startbootstrap-new-age/master/img/bg-pattern.png)',
  },
  headerText: {
    paddingTop: '10vw',
    paddingLeft: '10vw',
    paddingRight: '10vw',
  },
  content: {
    paddingTop: '20vh',
    paddingLeft: '10vw',
    paddingRight: '10vw',
    color: '#484848',
  },
  button: {
    textAlign: 'center',
    paddingTop: '2vh',
  },
}));

const Parallax = () => {
  const [isSmallDevice, setSmallDevice] = React.useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleRoute = (href) => {
    navigate(href);
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    setSmallDevice(window.innerWidth <= 760);
  });

  return (
    <>
      <ReactParallax strength={0}>
        <section className={classes.root}>
          <Grid container spacing={3} className={classes.grid}>
            <Grid item xs={12}>
              <Header />
              <div className={classes.info} style={isSmallDevice ? { paddingTop: '3vh' } : { paddingTop: '12vh' }}>
                <Carousel indicators={false}>
                  <Carousel.Item key={2}>
                    <Typography
                      variant="body1"
                      className={classes.content}
                      marked="center"
                      align="center"
                      style={{ textAlign: 'justify' }}
                    >
                      <b>“BHAGAT & KUMAR” </b>
                      was a dream, which was conceptualized by the co-founders-
                      Mr. Mohit Bhagat & Mr. Yatheen Kumar, since their college
                      days. As Dr. APJ Abdul Kalam has aptly said, “You have to
                      dream, before the dreams comes true”. Hence, To add wings
                      to their dreams, both the partners, left their lucrative
                      corporate jobs & headed towards a new avenue, on 14th Feb
                      2019 -the day on which, B&K was formed.
                    </Typography>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="secondary"
                        align="center"
                        onClick={() => handleRoute('/about?scroll=partner')}
                      >
                        Know More
                      </Button>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item key={4}>
                    <Typography
                      variant="body1"
                      className={classes.content}
                      marked="center"
                      align="center"
                      style={{ textAlign: 'justify' }}
                    >
                      At B&K, we uphold the basic values of integrity, honesty
                      and transparency in the assignments we undertake. Hence,
                      it is pertinent enough that as a team, we ensure that a
                      high level of integrity is maintained within the team and
                      with clients as well.
                    </Typography>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="secondary"
                        align="center"
                        onClick={() => handleRoute('/values')}
                      >
                        Know More
                      </Button>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item key={3}>
                    <Typography
                      variant="body1"
                      className={classes.content}
                      marked="center"
                      align="center"
                      style={{ textAlign: 'justify' }}
                    >
                      We take pride in working with a team of professional and
                      highly efficient members, who not only takes ownership of
                      their individual tasks, but also ensures, that the firm’s
                      values are not compromised with, at the meagre cost of
                      rendering services to the clients. Our values and ethos
                      comes first, rather than short term individual goals.
                    </Typography>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="secondary"
                        align="center"
                        onClick={() => handleRoute('/about')}
                      >
                        Know More
                      </Button>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item key={5}>
                    <Typography
                      variant="body1"
                      className={classes.content}
                      marked="center"
                      align="center"
                      style={{ textAlign: 'justify' }}
                    >
                      Our client speak for our services, &quot;For us at Amar
                      Jyothi Automobiles, working with M/s B&K has been the best
                      decision of our organisation. The service they offer is
                      professional, fast, reliable and absolutely unrivalled in
                      terms of value, quality, support and performance.&quot;
                    </Typography>
                    <div className={classes.button}>
                      <Button
                        variant="contained"
                        color="secondary"
                        align="center"
                        // eslint-disable-next-line no-undef
                        onClick={() => window.scrollTo(0, 2500)}
                      >
                        Know More
                      </Button>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Grid>
          </Grid>
        </section>
        <Background>
          <div className={classes.img}>
            <div className={classes.pattern} />
          </div>
        </Background>
      </ReactParallax>
    </>
  );
};

export default Parallax;
