/* eslint-disable no-undef */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import sendEmail from '../../utils/send-email';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    paddingRight: '35px',
  },
  content: {
    margin: '8px',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    width: '100%',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}));

const Transition = React.forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction="up" ref={ref} {...props} />
));

export default function ContactUs(props) {
  const classes = useStyles();
  const { onClose, open } = props;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState();
  const [number, setNumber] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [numberError, setNumberError] = useState('');
  const [emailError, setEmailError] = useState('');
  const emailOrNumberRequired = 'Mobile number or Email is Required';
  const invalidNumber = 'Invalid Mobile number';
  const invalidEmail = 'Invalid Email address';

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const validateEmail = (eml = email) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(eml).toLowerCase());
  };

  const handleClose = () => {
    onClose();
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value && !validateEmail(e.target.value)) {
      setEmailError(invalidEmail);
    } else {
      setEmailError('');
      if (numberError === emailOrNumberRequired) {
        setNumberError('');
      }
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    setNumber(e.target.value);
    if (e.target.value && e.target.value.length <= 6) {
      setNumberError(invalidNumber);
    } else {
      setNumberError('');
      if (emailError === emailOrNumberRequired) {
        setEmailError('');
      }
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendEmail = () => {
    if (email === '' && number === '') {
      setEmailError(emailOrNumberRequired);
      setNumberError(emailOrNumberRequired);
      return;
    }
    setStatus('');
    setLoading(true);
    sendEmail(
      name || 'NOT PROVIDED',
      number || 'NOT PROVIDED',
      email || 'NOT PROVIDED',
      message || 'NOT PROVIDED',
    )
      .then((isSent) => {
        if (isSent) {
          setLoading(false);
          setStatus('sent');
          setSuccess(true);
        } else {
          setLoading(false);
          setSuccess(false);
          setStatus('error');
        }
      })
      .catch(() => {
        setLoading(false);
        setSuccess(false);
        setStatus('error');
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      TransitionComponent={Transition}
      keepMounted
      open={open}
    >
      <section className={classes.root}>
        <TextField
          id="filled-name-input"
          label="Name"
          variant="outlined"
          value={name}
          onChange={handleNameChange}
          fullWidth
          className={classes.content}
        />
        <br />
        <TextField
          id="filled-email-input"
          label="Email"
          error={emailError !== ''}
          variant="outlined"
          helperText={emailError}
          fullWidth
          value={email}
          onChange={handleEmailChange}
          className={classes.content}
        />
        <br />
        <TextField
          id="standard-number"
          label="Mobile Number"
          type="number"
          error={numberError !== ''}
          helperText={numberError}
          value={number}
          onChange={handleMobileNumberChange}
          fullWidth
          variant="outlined"
          className={classes.content}
        />
        <br />
        <TextField
          id="outlined-multiline-static"
          label="Message"
          multiline
          rows={4}
          value={message}
          onChange={handleMessageChange}
          fullWidth
          variant="outlined"
          className={classes.content}
        />
        <br />
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="primary"
            // className={classes.content}
            fullWidth
            disabled={loading || emailError !== '' || numberError !== ''}
            className={buttonClassname}
            onClick={() => handleSendEmail()}
            endIcon={<SendIcon />}
          >
            Send
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
        {status === 'sent' && (
          <p style={{ margin: '8px', color: 'green' }}>
            Mail has been sent succcessfully!
          </p>
        )}
        {status === 'error' && (
          <p style={{ margin: '8px', color: 'red' }}>
            Oops something went wrong!
          </p>
        )}
      </section>
    </Dialog>
  );
}

ContactUs.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
