import React, { useEffect, useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import Divider from '@material-ui/core/Divider';
import { Helmet } from 'react-helmet';
import Fab from '@material-ui/core/Fab';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Parallax from '../components/Parallax';
import theme from '../styles/theme';
import About from '../components/About';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials/index';
import Footer from '../components/Footer';
import ResponsiveDialog from '../components/Dialog';
import ContactUs from '../components/ContactUs/index';
import Logo from '../images/logo.png';

const IndexPage = () => {
  const [isSmallDevice, setSmallDevice] = useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    setSmallDevice(window.innerWidth <= 760);
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bhagat & Kumar - Chartered Accountants | Home</title>
        <meta name="title" content="Bhagat and Kumar - Chartered Accountants | HOME" />
        <meta
          name="description"
          content="Welcome to Bhagat and Kumar Chartered Accountants, Founded in 2019, Bhagat & Kumar (B&K), is a Chartered Accountants
          firm, engaged into rendering multi-disciplinary services in the field
          of audit and assurance, taxation, project financing, advisory services
          etc."
        />
        <meta name="keywords" content="Chartered Accountants, Bengaluru, Mangalore, B&K, Bhagat and Kumar, Bhagat & Kumar, Accountants" />

        <meta property="og:title" content="Bhagat & Kumar" />
        <meta property="og:url" content={`${process.env.SITE_URL}`} />
        <meta
          property="og:description"
          content="Founded in 2019, Bhagat & Kumar (B&K), is a Chartered Accountants
          firm, engaged into rendering multi-disciplinary services in the field
          of audit and assurance, taxation, project financing, advisory services
          etc."
        />
        <meta property="og:image" content={Logo} />

        <meta property="twitter:card" content="about" />
        <meta property="twitter:image" content={Logo} />
        <meta property="twitter:site" content="@bhagatandkumar" />
        <meta property="twitter:title" content="Bhagat & Kumar" />
        <meta
          property="twitter:description"
          content="Founded in 2019, Bhagat & Kumar (B&K), is a Chartered Accountants
          firm, engaged into rendering multi-disciplinary services in the field
          of audit and assurance, taxation, project financing, advisory services
          etc."
        />
      </Helmet>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ResponsiveDialog />
        <Parallax />
        <About />
        <Divider light />
        <Services />
        <Divider light />
        <Testimonials />
        <Footer />
      </MuiThemeProvider>
    </>
  );
};
export default IndexPage;
