import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '../Typography';

const styles = (theme) => ({
  root: {
    paddingTop: '5vw',
    paddingBottom: '10vw',
    backgroundColor: '#F8F8F8',
  },
  papers: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paperWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '30vh',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: 100,
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $paperBackdrop': {
      opacity: 0.7,
    },
    '&:hover $paperMarked': {
      opacity: 0,
    },
    '&:hover $paperTitle': {
      border: '4px solid currentColor',
    },
  },
  paperButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: '#FFFF67',
    color: theme.palette.common.white,
  },
  paper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  paperBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
  },
  paperTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  paperMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
  },
  header: {
    paddingBottom: '50px',
  },
});

function Services(props) {
  const { classes } = props;

  const cards = [
    {
      title: 'Tax Advisory',
      width: '20%',
      backgroundColor: '#FF8C00',
    },
    {
      title: 'Business Structuring & Re-organization',
      width: '40%',
      backgroundColor: '#B0C4DE',
    },
    {
      title: 'Incorporation of Companies, LLP & Firms',
      width: '40%',
      backgroundColor: '#B0B0B0',
    },
    {
      title: 'Audit & Assurance',
      width: '30%',
      backgroundColor: '#66CDAA',
    },
    {
      title: 'Company & Shares Valuation',
      width: '25%',
      backgroundColor: '#87CEFA',
    },
    {
      title: 'Management & Corporate advisory',
      width: '20%',
      backgroundColor: '#cc0099',
    },
    {
      title: 'Project Financing & Debt Re-structuring',
      width: '25%',
      backgroundColor: '#20B2AA',
    },
    {
      title: 'Bank Audits',
      width: '20%',
      backgroundColor: '#ff80df',
    },
    {
      title: 'Statuary & Internal Audit',
      width: '30%',
      backgroundColor: '#ff6666',
    },
    {
      title: 'Assessment, Appeals & Litigation',
      width: '30%',
      backgroundColor: '#00ace6',
    },
    {
      title: 'Transfer Pricing',
      width: '20%',
      backgroundColor: '#b38600',
    },
  ];

  return (
    <section className={classes.root}>
      <Container>
        <section>
          <Grid container className={classes.header} spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant="h3" align="center" marked="center">
                Our Services
              </Typography>
            </Grid>
          </Grid>
        </section>
        <div className={classes.papers}>
          {cards.map((data) => (
            <ButtonBase
              key={data.title}
              className={classes.paperWrapper}
              style={{
                width: data.width,
              }}
            >
              <Paper className={classes.paper} />
              <div
                className={classes.paperBackdrop}
                style={{ backgroundColor: data.backgroundColor }}
              />
              <div className={classes.paperButton}>
                <Typography
                  component="h3"
                  variant="h6"
                  color="inherit"
                  className={classes.paperTitle}
                >
                  {data.title}
                  <div className={classes.paperMarked} />
                </Typography>
              </div>
            </ButtonBase>
          ))}
        </div>
      </Container>
    </section>
  );
}

Services.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Services);
