/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { CardMedia } from '@material-ui/core';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Typography from '../Typography';
import Avatar from '../../images/img_avatar.png';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#F8F8F8',
  },
  container: {
    marginTop: '5vw',
    marginBottom: '10vw',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  post: {
    color: '#3BA6FB',
    paddingBottom: '10px',
  },
  clientImage: {
    height: '150px',
    borderRadius: '50%;',
    width: '150px',
  },
  heading: {
    padding: '25px',
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
  quote: {
    transform: 'rotate(-180deg)',
  },
  card: {
    maxHeight: '100px',
    borderRadius: '50%;',
  },
  header: {
    paddingBottom: '50px',
  },
});

const items = [
  {
    id: 1,
    cardExtra: '"Our Client\'s Speaks"',
    post: '',
    body:
      'I have always found Mohit to be very approachable. He clearly has a very wide accounting and tax knowledge base, which he is only willing to share. I have known him always to be professional, organised and unflappable. Mohit knows the importance of good service delivered on a timely basis in an easy to understand and friendly manner.',
  },
  {
    id: 2,
    cardExtra: '"Our Client\'s Speaks"',
    post: '',
    body:
      'Yatheen has looked after our personal tax matters and returns for many years. We have found him to be very accessible, approachable and responsive. He is also extremely knowledgeable and professional in all our dealings, providing top class client service and advice. Thank you Yatheen. We would have absolutely no hesitation in highly recommending B&K for personal tax matters.',
  },
];

const item2 = [
  {
    id: 3,
    cardExtra: '"Our Client\'s Speaks"',
    post: '',
    body:
      'For us at Amar Jyothi Automobiles, working with M/s B&K has been the best decision of our organisation. The service they offer is professional, fast, reliable and absolutely unrivalled in terms of value, quality, support and performance.',
  },
  {
    id: 4,
    cardExtra: '"Our Client\'s Speaks"',
    post: '',
    body:
      'Mohit Bhagat is very approachable & he give concise positive advice. If information is required quickly, then B&K are always ready to help, I would not hesitate to recommend their services.',
  },
];

const item3 = [
  {
    id: 5,
    cardExtra: '"Our Client\'s Speaks"',
    post: '',
    body:
      'I Strongly recommend B&K, very highly for their committed prompt service, timely communication & most of all to their expert advice when in time of need. All the best B&K.',
  },
  {
    id: 6,
    cardExtra: '"Our Client\'s Speaks"',
    post: '',
    body:
      'We are clients of B&K since past few years, we are pleased with their professionalism & their response is very timely. Mohit is very knowledgeable & ethical. We feel we are in good hands, I would recommend to anyone who is looking for a Corporate CA`s with immense knowledge of Corporate Taxation, very professional & courteous as they understand the core business.',
  },
];

const item4 = [
  {
    id: 7,
    cardExtra: '"Our Client\'s Speaks"',
    post: '',
    body:
      'B&K is one point solution for all our things related to Tax filing & computation, I appreciate their services as they always go extra mile in providing best of experiences with same spirit, as was in my case when I reached out to them even on holidays.',
  },
  {
    id: 8,
    cardExtra: '"Our Client\'s Speaks"',
    post: '',
    body:
      'I m very happy with the services provided by Mr Mohit. They are doing a wonderful job from last two years on my tax preparation and tax planning. I would highly recommend them.',
  },
];

const item5 = [
  {
    id: 9,
    cardExtra: '"Our Client\'s Speaks"',
    post: '',
    body:
      'Mohit Sir, is very determinant towards his work and have wide knowledge about tax and accounts, along with very punctual & discipline, also very friendly towards his clients.',
  },
];

function Testimonial(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <section>
          <Grid container className={classes.header} spacing={2}>
            <Grid key={1} item xs={12} md={3}>
              <Typography variant="h3" align="center" marked="center">
                Testimonials
              </Typography>
            </Grid>
          </Grid>
        </section>
        <Carousel
          indicators={false}
          prevIcon={(
            <span
              aria-hidden="true"
              className="carousel-control-prev-icon"
              style={{ filter: 'invert(100%)' }}
            />
          )}
          nextIcon={(
            <span
              aria-hidden="true"
              className="carousel-control-next-icon"
              style={{ filter: 'invert(100%)' }}
            />
          )}
        >
          <Carousel.Item>
            <Grid container className={classes.root} spacing={2}>
              {items.map((row) => (
                <Grid key={row.id} item xs={12} md={6}>
                  <div className={classes.item}>
                    <CardMedia
                      src={Avatar}
                      image={Avatar}
                      className={classes.clientImage}
                      alt="client"
                    />
                    <Typography variant="h5" className={classes.title}>
                      {row.cardExtra}
                    </Typography>
                    <Typography variant="h6" className={classes.post}>
                      {row.post}
                    </Typography>
                    <Typography variant="body1" style={{ textAlign: 'justify' }}>
                      <FormatQuoteIcon className={classes.quote} />
                      {row.body}
                      <FormatQuoteIcon />
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Carousel.Item>
          <Carousel.Item>
            <Grid container className={classes.root} spacing={2}>
              {item2.map((row) => (
                <Grid key={row.id} item xs={12} md={6}>
                  <div className={classes.item}>
                    <CardMedia
                      src={Avatar}
                      image={Avatar}
                      className={classes.clientImage}
                      alt="client"
                    />
                    <Typography variant="h5" className={classes.title}>
                      {row.cardExtra}
                    </Typography>
                    <Typography variant="h6" className={classes.post}>
                      {row.post}
                    </Typography>
                    <Typography variant="body1" style={{ textAlign: 'justify' }}>
                      <FormatQuoteIcon className={classes.quote} />
                      {row.body}
                      <FormatQuoteIcon />
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Carousel.Item>
          <Carousel.Item>
            <Grid container className={classes.root} spacing={2}>
              {item3.map((row) => (
                <Grid key={row.id} item xs={12} md={6}>
                  <div className={classes.item}>
                    <CardMedia
                      src={Avatar}
                      image={Avatar}
                      className={classes.clientImage}
                      alt="client"
                    />
                    <Typography variant="h5" className={classes.title}>
                      {row.cardExtra}
                    </Typography>
                    <Typography variant="h6" className={classes.post}>
                      {row.post}
                    </Typography>
                    <Typography variant="body1" style={{ textAlign: 'justify' }}>
                      <FormatQuoteIcon className={classes.quote} />
                      {row.body}
                      <FormatQuoteIcon />
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Carousel.Item>
          <Carousel.Item>
            <Grid container className={classes.root} spacing={2}>
              {item4.map((row) => (
                <Grid key={row.id} item xs={12} md={6}>
                  <div className={classes.item}>
                    <CardMedia
                      src={Avatar}
                      image={Avatar}
                      className={classes.clientImage}
                      alt="client"
                    />
                    <Typography variant="h5" className={classes.title}>
                      {row.cardExtra}
                    </Typography>
                    <Typography variant="h6" className={classes.post}>
                      {row.post}
                    </Typography>
                    <Typography variant="body1" style={{ textAlign: 'justify' }}>
                      <FormatQuoteIcon className={classes.quote} />
                      {row.body}
                      <FormatQuoteIcon />
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Carousel.Item>
          <Carousel.Item>
            <Grid container className={classes.root} spacing={2}>
              {item5.map((row) => (
                <Grid key={row.id} item xs={12} md={12}>
                  <div className={classes.item}>
                    <CardMedia
                      src={Avatar}
                      image={Avatar}
                      className={classes.clientImage}
                      alt="client"
                    />
                    <Typography variant="h5" className={classes.title}>
                      {row.cardExtra}
                    </Typography>
                    <Typography variant="h6" className={classes.post}>
                      {row.post}
                    </Typography>
                    <Typography variant="body1" style={{ textAlign: 'justify' }}>
                      <FormatQuoteIcon className={classes.quote} />
                      {row.body}
                      <FormatQuoteIcon />
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Carousel.Item>
        </Carousel>
      </Container>
    </section>
  );
}

Testimonial.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.any.isRequired,
};

export default withStyles(styles)(Testimonial);
