import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { useTheme } from '@material-ui/core/styles';

export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const disclaimer = window.sessionStorage.getItem('disclaimer');
    if (!disclaimer) {
      setOpen(true);
    }
  });

  const handleClose = (isAgree) => {
    if (isAgree) {
      // eslint-disable-next-line no-undef
      window.sessionStorage.setItem('disclaimer', isAgree);
      setOpen(false);
    } else {
      // eslint-disable-next-line no-undef
      window.location.href = 'https://google.com';
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Disclaimer & Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'justify' }}>
            <div>
              As per the provisions of the The Chartered Accountants Act, 1949,
              we are not permitted to solicit work and advertise. By clicking on
              the I agree below, the user acknowledges the following:
            </div>
            <br />
            <div>
              <LabelImportantIcon />
              there has been no advertisement, personal communication,
              solicitation, invitation or inducement of any sort whatsoever from
              us to solicit any work through this website;
            </div>
            <br />
            <div>
              <LabelImportantIcon />
              the user wishes to gain more information about us for his/her own
              information and use;
            </div>
            <br />
            <div>
              <LabelImportantIcon />
              the information about us is provided to the user only on his/her
              specific request.
            </div>
            <br />
            <div>
              <LabelImportantIcon />
              the information provided under this website is solely available at
              your request for informational purposes only, should not be
              interpreted as soliciting or advertisement.
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} color="primary">
            Agree
          </Button>
          <Button onClick={() => handleClose(false)} color="primary">
            Disagree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
