/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';
import { useNavigate } from '@reach/router';
import Paper from '@material-ui/core/Paper';
import Typography from '../Typography';

// import background from '../../images/background.png';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#F8F8F8',
  },
  container: {
    marginTop: '5vw',
    marginBottom: '10vw',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '50px',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  curvyLines: {
    // pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    zIndex: 0,
  },
  heading: {
    padding: '25px',
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
  header: {
    paddingBottom: '50px',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
});

const items = [
  {
    id: 1,
    href: '/values',
    cardExtra: 'Our Vision',
    body:
      'As we created, Vision 2025, it was quite apparent, that a ‘sense of purpose’ runs strongly though our organisation. "We aspire to be the Standard of Excellence & Ethics, the...',
  },
  {
    id: 2,
    href: '/values',
    cardExtra: 'Our Values',
    body:
      'At B&K, we uphold the basic values of integrity, honesty and transparency in the assignments we undertake. Hence, it is pertinent enough that as a team, we ensure that a high level of integrity... ',
  },
  {
    id: 3,
    href: '/about',
    cardExtra: 'Our Team',
    body: `We take pride in working with a team of professional and
      highly efficient members, who not only takes ownership of
      their individual tasks, but also ensures, that the firm’s
      values... `,
  },
  {
    id: 4,
    href: '/csr',
    cardExtra: 'Our CSR',
    body: `“B&K FOUNDATION
      , a trust formed by both the partners in 2020 which was formed for
      doing their bit for the society, both partners being brought up in
      humble background, realized the essence of a...`,
  },
];

function About(props) {
  const { classes } = props;

  const navigate = useNavigate();

  const handleRoute = (href) => {
    navigate(href);
  };

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        {/* <img
          src={background}
          className={classes.curvyLines}
          alt="curvy lines"
        /> */}
        <section>
          <Grid container className={classes.header} spacing={2}>
            <Grid key={1} item xs={12} md={3}>
              <Typography variant="h3" align="center" marked="center">
                About Us
              </Typography>
            </Grid>
          </Grid>
        </section>
        <Grid container className={classes.root} spacing={2}>
          {items.map((row) => (
            <Grid key={row.id} item xs={12} md={3}>
              <Paper style={{ backgroundColor: 'white', height: '370px' }}>
                <div className={classes.item}>
                  <Typography variant="h5" className={classes.title}>
                    {row.cardExtra}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ paddingBottom: '20px', textAlign: 'justify' }}
                  >
                    {row.body}
                  </Typography>
                  <Button
                    color="primary"
                    fullWidth
                    className={styles.cta}
                    onClick={() => handleRoute(row.href)}
                  >
                    Know More
                    <ChevronRightRounded />
                  </Button>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
  );
}

About.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.any.isRequired,
};

export default withStyles(styles)(About);
